/* eslint-disable no-undef */
import './scss/style.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import App from '@akcelita/uwe-all/lib/DefaultApp';

// import registerServiceWorker from '@akcelita/uwe-all/lib/registerServiceWorker';
import fetchApi from '@akcelita/uwe-all/lib/util/fetchApi';
import { AuthProvider, getToken } from '@akcelita/uwe-all/lib/context/AuthContext';

import { useZohoDeskWidget } from '@akcelita/uwe-all/lib/util/useZohoDeskWidget';
import { ENDPOINT, SENTRY_DSN } from './constants';
import { LANGS, INIT_LANG } from './i18n'

function registerServiceWorker(){}

fetchApi.configure({
  defaults: { baseurl: ENDPOINT },
  verbs: ['get', 'post', 'put', 'delete'],
  helpers: {
    api: (url, options, fetchApi) => fetchApi(url, {
      auth: { type: 'Bearer', token: getToken() },
      json: true,
      ...options
    }, fetchApi),
    external: (url, options, fetchApi) => fetchApi(url, {
      json: true,
      baseUrl: undefined,
      ...options
    }, fetchApi),
    lookup: (url, options, fetchApi) => fetchApi(`form-lookup/${url}`, {
      auth: { type: 'Bearer', token: getToken() },
      json: true,
      useCache(url, options) { return options.method === 'get'},
      ...options
    }, fetchApi)
  }
});


if (SENTRY_DSN) {
  Sentry.init({
      dsn: SENTRY_DSN,
      // integrations: [new BrowserTracing()],
      // tracesSampleRate: 1.0,
  });  
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


function MainApp() {
  useZohoDeskWidget();

  return (
      <BrowserRouter basename={baseUrl}>
        <AuthProvider>
          <App LANGS={LANGS} INIT_LANG={INIT_LANG} />
        </AuthProvider>
      </BrowserRouter>
  )
}

const root = createRoot(rootElement);
root.render(<MainApp />);

registerServiceWorker();

